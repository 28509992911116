@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap'); */

@import url("https://fonts.googleapis.com/css2?family=Croissant+One&family=Parisienne&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Sacramento&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Fuggles&display=swap");

/* a {
  @apply text-blue-700;
} */

/* a:hover {
  @apply border-b-0 border-orange-100;
} */

.content p {
  @apply my-6;
}

.content ul {
  @apply my-6;
}

body {
  background-color: #f0e9e1 !important;
}

.group:focus .group-focus\:flex {
  display: flex;
}

@keyframes fadeInOut {
  0% {
    opacity: 0; /* Start with 0% opacity (fully transparent) */
  }
  25% {
    opacity: 1; /* Fade in to 100% opacity */
  }
  75% {
    opacity: 1; /* Stay fully opaque */
  }
  100% {
    opacity: 0; /* Fade out to 0% opacity */
  }
}

